import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BurgerMenu, Header, Loader } from "../../components";
import ActualNews from "../../pages/ActualNews";
import ActualNewsArticle from "../../pages/ActualNewsArticle";
import appConfig from "../../util/appConfig";
import { useAppDispatch } from "../../store";
import { userToken } from "../../util/userToken";
import { getMe, getMeInfo, meInfoCheck } from "../../store/slices/user";

const BulkyTrashRegistration = lazy(() => import("../../pages/BulkyTrash"));
const Calendar = lazy(() => import("../../pages/Calendar"));
const Main = lazy(() => import("../../pages/Main"));
const Login = lazy(() => import("../../pages/Login"));
const Registration = lazy(() => import("../../pages/Registration"));
const Leerungsdaten = lazy(() => import("../../pages/Leerungsdaten"));
const Profile = lazy(() => import("../../pages/Profile"));
const RecycleCenterAppointment = lazy(
  () => import("../../pages/RecycleCenterAppointment")
);
const Schadstoffmobil = lazy(() => import("../../pages/Schadstoffmobil"));
const Privacy = lazy(() => import("../../pages/DataPrivacy"));
const Reclamation = lazy(() => import("../../pages/Reclamation"));
const Behaelterverwaltung = lazy(
  () => import("../../pages/Behaelterverwaltung")
);
const Eigenkompostierer = lazy(() => import("../../pages/Eigenkompostierer"));
const YellowBin = lazy(() => import("../../pages/YellowBin"));
const Behaeltergemeinschaft = lazy(
  () => import("../../pages/Behaeltergemeinschaft")
);
const Contact = lazy(() => import("../../pages/Contact"));
const Imprint = lazy(() => import("../../pages/Imprint"));
const LeerungsdatenEinsicht = lazy(
  () => import("../../pages/LeerungsdatenEinsicht")
);
const SepaManagement = lazy(() => import("../../pages/SepaManagement"));
const KassenzeichenVerwaltung = lazy(
  () => import("../../pages/KassenzeichenVerwaltung")
);
const Eigentuemerwechsel = lazy(() => import("../../pages/Eigentuemerwechsel"));
const Zusatzleistungen = lazy(() => import("../../pages/Zusatzleistungen"));
const Stammdaten = lazy(() => import("../../pages/Stammdaten"));
const Bescheide = lazy(() => import("../../pages/Bescheide"));
const AddObject = lazy(() => import("../../pages/AddObject"));
const EnterCode = lazy(() => import("../../pages/EnterCode"));

const MainContent = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // if (["lra", "calw", "viersen"].includes(appConfig.logo)) {
    if (["calw", "viersen"].includes(appConfig.logo)) {
      const token = userToken.get();
      if (token) {
        dispatch(getMe(token));
      }
    }
    if (["lra"].includes(appConfig.logo)) {
      const token = searchParams.get("t");
      if (token) {
        dispatch(getMeInfo(token));
        searchParams.delete("t");

        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }

      const tok = userToken.get();

      if(tok) {
        dispatch(meInfoCheck(tok));
      }
    }
  }, []);

  const generateClassName = (type: string) => {
    switch (type) {
      case "viersen": {
        if (location.pathname === "/login") {
          return "loginViersenMainWrapper";
        } else {
          return "viersenMainWrapper";
        }
      }
      default: {
        return "mainWrapper";
      }
    }
  };

  return (
    <>
      {/* {!["viersen"].includes(appConfig.logo) ? <Header /> : null} */}
      <Header />
      <div className={generateClassName(appConfig.logo)} id={appConfig.logo === 'viersen' ? 'sepa-wrapper': ''}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="login" element={<Login />} />

            <Route path="registration" element={<Registration />} />
            {["sensis", "awl", "bk", "ebwo", "lra", "calw"].includes(
              appConfig.logo
            ) && (
              <Route
                path="sperrmuell-anmelden"
                element={<BulkyTrashRegistration />}
              />
            )}
            {["ebwo"].includes(appConfig.logo) && (
              <Route
                path="blitzsperrmuell-anmelden"
                element={<BulkyTrashRegistration />}
              />
            )}
            {["sensis", "awl", "bk"].includes(appConfig.logo) && (
              <Route path="calendar" element={<Calendar />} />
            )}
            {["sensis", "lra", "calw"].includes(appConfig.logo) && (
              <Route path="leerungsdaten" element={<Leerungsdaten />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="profile" element={<Profile />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="actual" element={<ActualNews />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="actual/:articleId" element={<ActualNewsArticle />} />
            )}

            {["sensis"].includes(appConfig.logo) && (
              <Route
                path="wertstoffhoftermine"
                element={<RecycleCenterAppointment />}
              />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="schadstoffmobil" element={<Schadstoffmobil />} />
            )}
            <Route path="privacy" element={<Privacy />} />
            {["lra", "calw"].includes(appConfig.logo) && (
              <Route path="reklamation" element={<Reclamation />} />
            )}
            {["lra", "calw", "sensis"].includes(appConfig.logo) && (
              <Route
                path="behaelterverwaltung"
                element={<Behaelterverwaltung />}
              />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route path="eigenkompostierer" element={<Eigenkompostierer />} />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route path="gelbe-tonne" element={<YellowBin />} />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route
                path="behaeltergemeinschaft"
                element={<Behaeltergemeinschaft />}
              />
            )}
            {["calw", "lra"].includes(appConfig.logo) && (
              <Route
                path="leerungsdaten-einsicht"
                element={<LeerungsdatenEinsicht />}
              />
            )}
            {["lra", "calw", "viersen"].includes(appConfig.logo) && (
              <Route path="sepa-management" element={<SepaManagement />} />
            )}
            {["lra"].includes(appConfig.logo) && (
              <Route
                path="eigentuemerwechsel"
                element={<Eigentuemerwechsel />}
              />
            )}
            {["lra"].includes(appConfig.logo) && (
              <Route path="zusatzleistungen" element={<Zusatzleistungen />} />
            )}
            <Route path="contact" element={<Contact />} />
            <Route path="impressum" element={<Imprint />} />
            <Route
              path="kassenzeichen-verwaltung"
              element={<KassenzeichenVerwaltung />}
            />
            <Route path="stammdaten" element={<Stammdaten />} />
            {["calw"].includes(appConfig.logo) ? (
              <Route path="bescheide" element={<Bescheide />} />
            ) : null}
            {["calw", "lra"].includes(appConfig.logo) ? (
              <Route path="add-object" element={<AddObject />} />
            ) : null}
            {["calw", "lra"].includes(appConfig.logo) ? (
              <Route path="enter-code" element={<EnterCode />} />
            ) : null}
          </Routes>
        </Suspense>
      </div>
      <BurgerMenu />
    </>
  );
};

export default MainContent;
