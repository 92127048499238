import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAdminNotificationItemsCount,
  setErrorMessage,
  setIsConfirmationPopupActive,
  // setIsLoading,
  setIsToasterError,
  setIsToasterSuccess,
  setSuccessMessage,
} from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";
import appConfig from "../../util/appConfig";

export interface IBehaeltervervaltungBody {
  behtyp: string;
  behfarbeid: number;
  behfarbe: string;
  behfraktion: string;
  behgroesseid: number;
  behgroesse: string;
  behvolumen: number;
  behrhythmusid: number;
  behrhythmus: string;
  behserviceid: null | string;
  behservice: null | string;
  transpondernr: string;
  inventarnr: string;
  behid: number;
  kav: string;
  isChecking: boolean;
  hasNotMultipleGray?: boolean;
  behservicebescheidtext?: string;
}

export interface IBehaeltervervaltungResponseBody
  extends IBehaeltervervaltungBody {
  id: string;
  createdAt: string;
  status: string;
  behId: string | number;
  username: string;
  usersurname: string;
  phoneNumber: string;
  requestType: string;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  adrId: string | number;
  objektId: string | number;
  additionalInfo?: string;
  isFreeBioBin?: boolean;
  areAdditionalServicesAvailable?: boolean;
  binQuantity?: number;
  kavNumbers?: string[];
  saisonBin?: { bin: number; month: string[] }[];
  errorMessage?: string;
}

export interface IBehaelterverwaltungState {
  client: {
    behaelterData: IBehaeltervervaltungBody[];
    isLoading: boolean;
  };
  admin: {
    itemsCount: number;
    behaeltervervaltunData: IBehaeltervervaltungResponseBody[];
    behaeltervervaltunItem: IBehaeltervervaltungResponseBody | null;
    isBehaeltervervaltunItemDataLoading: boolean;
  };
}

const initialState: IBehaelterverwaltungState = {
  client: {
    behaelterData: [],
    isLoading: false,
  },
  admin: {
    behaeltervervaltunData: [],
    behaeltervervaltunItem: null,
    isBehaeltervervaltunItemDataLoading: false,
    itemsCount: 0,
  },
};

export const getBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/getBehaelter",
  async (
    {
      gemeindeNr,
      kassenzeichen,
      objId,
    }: {
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objId: string | number;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.get.getBehaelter({
        gemeindeNr,
        kassenzeichen,
        objId,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      // dispatch(setIsLoading(false));
    }
  }
);

export const addBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/addBehaelter",
  async (
    {
      quantity,
      type,
      volume,
      additionalBin,
      additionalBinVolume,
      gemeindeNr,
      objektId,
      additionalBioBinQuantity,
      selectedMonth,
      behRhythmus,
    }: {
      type: string;
      volume: string;
      quantity: number;
      additionalBin?: boolean;
      additionalBinVolume?: string;
      gemeindeNr: string | number;
      objektId: string | number;
      selectedMonth?: { [key: number]: string[] };
      additionalBioBinQuantity?: number;
      behRhythmus?: string;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));

      const data = await API.behaelterverwaltung.post.addBehaelter({
        quantity,
        type,
        volume,
        additionalBin,
        additionalBinVolume,
        gemeindeNr,
        objektId,
        additionalBioBinQuantity,
        selectedMonth,
        behRhythmus,
      });

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Erfolgreich erstellt!"));

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsConfirmationPopupActive(false));
      // dispatch(setIsLoading(false));
    }
  }
);

export const defectBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/defectBehaelter",
  async (
    {
      id,
      reason,
      gemeindeNr,
      kassenzeichen,
      objektId,
      additionalBrownBin,
    }: {
      id: string;
      reason: string;
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objektId: string | number;
      additionalBrownBin?: number;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.post.defectBehaelter({
        id,
        reason,
        gemeindeNr,
        kassenzeichen,
        objektId,
        additionalBrownBin,
      });

      // if (data.message) {
      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Ihr Behälter wurde defekt gemeldet."));
      // }

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      // dispatch(setIsLoading(false));
      dispatch(setIsConfirmationPopupActive(false));
    }
  }
);

export const disappearedBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/disappearedBehaelter",
  async (
    {
      id,
      disappeared,
      gemeindeNr,
      kassenzeichen,
      objektId,
    }: {
      id: string;
      disappeared: boolean;
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objektId: string | number;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.post.disappearedBehaelter({
        id,
        disappeared,
        gemeindeNr,
        kassenzeichen,
        objektId,
      });

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Ihr Behälter wurde verschwunden gemeldet."));

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      // dispatch(setIsLoading(false));
      dispatch(setIsConfirmationPopupActive(false));
    }
  }
);

export const updateBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/updateBehaelter",
  async (
    {
      id,
      volume,
      gemeindeNr,
      kassenzeichen,
      objektId,
    }: {
      id: string;
      volume: string;
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objektId: string | number;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.put.updateBehaelter({
        id,
        volume,
        gemeindeNr,
        kassenzeichen,
        objektId,
      });

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Ihr Auftrag wurde erfolgreich übermittelt."));

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      // dispatch(setIsLoading(false));
      dispatch(setIsConfirmationPopupActive(false));
    }
  }
);

export const removeBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/removeBehaelter",
  async (
    {
      id,
      gemeindeNr,
      kassenzeichen,
      objektId,
    }: {
      id: string;
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objektId: string | number;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.delete.removeBehaelter({
        id,
        gemeindeNr,
        kassenzeichen,
        objektId,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsConfirmationPopupActive(false));
      // dispatch(setIsLoading(false));
    }
  }
);

export const getAdminBehaelterverwaltung = createAsyncThunk(
  "behaelterverwaltung/admin/getAdminBehaelterverwaltung",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
      requestType,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null | string;
      endDate?: Date | null | string;
      status?: string[];
      signal: AbortSignal;
      requestType: string[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.behaelterverwaltung.get.getAdminBehaelterverwaltung({
          direction,
          page,
          pageSize,
          searchValue,
          sort,
          signal,
          endDate,
          startDate,
          status,
          requestType,
        });
      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);
export const getAdminBehaelterverwaltungById = createAsyncThunk(
  "behaelterverwaltung/admin/getAdminBehaelterverwaltungById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.behaelterverwaltung.get.getAdminBehaelterverwaltungById(id);
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);
export const updateStatusById = createAsyncThunk(
  "behaelterverwaltung/admin/updateStatusById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.behaelterverwaltung.put.updateStatusById({
        id,
        status,
      });
      if (["lra"].includes(appConfig.logo)) {
        dispatch(getAdminNotificationItemsCount());
      }
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const behaelterverwaltungSlice = createSlice({
  name: "behaelterverwaltung",
  initialState,
  reducers: {
    clearBehaelterData: (state) => {
      state.client.behaelterData = [];
      state.client.isLoading = false;
    },
    clearAdminBehaelter: (state) => {
      state.admin.itemsCount = 0;
      state.admin.behaeltervervaltunData = [];
    },
    clearAdminBehaelterItem: (state) => {
      state.admin.behaeltervervaltunItem = null;
      state.admin.isBehaeltervervaltunItemDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBehaelter.pending, (state) => {});
    builder.addCase(getBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
    });
    builder.addCase(getBehaelter.rejected, (state) => {});
    builder.addCase(addBehaelter.pending, (state) => {
      state.client.isLoading = true;
    });
    builder.addCase(addBehaelter.fulfilled, (state, action) => {
      // if (action.payload) {
      //   state.client.behaelterData = [...action.payload];
      // }
      state.client.isLoading = false;
    });
    builder.addCase(addBehaelter.rejected, (state) => {
      state.client.isLoading = false;
    });
    builder.addCase(updateBehaelter.pending, (state) => {
      state.client.isLoading = true;
    });
    builder.addCase(updateBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
      state.client.isLoading = false;
    });
    builder.addCase(updateBehaelter.rejected, (state) => {
      state.client.isLoading = false;
    });
    builder.addCase(removeBehaelter.pending, (state) => {
      state.client.isLoading = true;
    });
    builder.addCase(removeBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
      state.client.isLoading = false;
    });
    builder.addCase(removeBehaelter.rejected, (state) => {
      state.client.isLoading = false;
    });
    builder.addCase(defectBehaelter.pending, (state) => {
      state.client.isLoading = true;
    });
    builder.addCase(defectBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
      state.client.isLoading = false;
    });
    builder.addCase(defectBehaelter.rejected, (state) => {
      state.client.isLoading = false;
    });
    builder.addCase(disappearedBehaelter.pending, (state) => {
      state.client.isLoading = true;
    });
    builder.addCase(disappearedBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
      state.client.isLoading = false;
    });
    builder.addCase(disappearedBehaelter.rejected, (state) => {
      state.client.isLoading = false;
    });
    builder.addCase(getAdminBehaelterverwaltung.pending, (state) => {});
    builder.addCase(getAdminBehaelterverwaltung.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.itemsCount = action.payload.totalCount;
        state.admin.behaeltervervaltunData = [...action.payload.data];
      }
    });
    builder.addCase(getAdminBehaelterverwaltung.rejected, (state) => {});
    builder.addCase(getAdminBehaelterverwaltungById.pending, (state) => {
      state.admin.isBehaeltervervaltunItemDataLoading = true;
    });
    builder.addCase(
      getAdminBehaelterverwaltungById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.behaeltervervaltunItem = action.payload;
        }
        state.admin.isBehaeltervervaltunItemDataLoading = false;
      }
    );
    builder.addCase(getAdminBehaelterverwaltungById.rejected, (state) => {
      state.admin.isBehaeltervervaltunItemDataLoading = false;
    });
    builder.addCase(updateStatusById.pending, (state) => {
      state.admin.isBehaeltervervaltunItemDataLoading = true;
    });
    builder.addCase(updateStatusById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.behaeltervervaltunItem = action.payload;
      }
      state.admin.isBehaeltervervaltunItemDataLoading = false;
    });
    builder.addCase(updateStatusById.rejected, (state) => {
      state.admin.isBehaeltervervaltunItemDataLoading = false;
    });
  },
});

export const {
  clearBehaelterData,
  clearAdminBehaelter,
  clearAdminBehaelterItem,
} = behaelterverwaltungSlice.actions;

export const behaelterverwaltungReducer = behaelterverwaltungSlice.reducer;
